export enum CheckoutStepNames {
  Questionnaire = 'questionnaire ', // Questionnaire
  Identification = 'identification', // normal identification component
  Investment = 'amount', // CheckoutInvestment
  Terms = 'terms', // CheckoutLegal
  Payment = 'payment', // no component
  Status = 'status', // CheckoutStatus
}

export type Checkout = {
  assetId: string,
  sharesAmount: number,
  eurAmount: number,
  totalCosts: number,
  totalEmissionCosts: number,
  selectedDividendsFormatYear: [string, number],
  nextStep?: CheckoutStepNames | 'lastStep',
  type: 'one-off' | 'subscription' | 'gift-purchase',
  method?: 'ideal' | 'bancontact' | 'sofort',
  discountCode?: string,
};

export interface CheckoutCloudFunctionParameters {
  assetId: string,
  redirectUrl: string,
  sharesAmount: number,
  lang: string,
  selectedDividendsFormatYear: [string, number],
  brand?: string
  type?: string, // this is not a parameter in the CF but we still send it for now
  method?: string,
  discountCode?: string,
}

export type Step = {
  name: CheckoutStepNames,
  /**
   * what is `matched` in the router
   */
  route: string,
  /**
   * the pointer to the correct v18n property
   */
  display: string,
};
