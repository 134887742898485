import firebase from 'firebase/app';
import { Asset } from './asset';
import { Coupon, Investor } from './user';

/* eslint-disable no-use-before-define */
/* eslint-disable typescript/no-use-before-define */

/**
 * Defining all the types a Investment Subscription can have.
 */
 export const enum InvestmentSubscriptionStatus {
  Active = 'active',
  Cancelled = 'cancelled',
  OnHold = 'on-hold',
  PendingCancel = 'pending-cancel'
}

/**
 * The object to keep track of Investment Subscriptions.
 */
 export interface InvestmentSubscriptions {
  [paymentId: string]: InvestmentSubscriptionStatus;
}

/**
 * The upper db object for an Investment.
 * An investments always contains a subcollection of Payments.
 */
export interface Investment {
  id?: string;
  investor: firebase.firestore.DocumentReference | Investor;
  asset: firebase.firestore.DocumentReference | Asset;
  paidEuroTotal?: number;
  boughtSharesTotal?: number;
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime?: firebase.firestore.Timestamp;
  totalEarningAmount?: number;

  receivedSharesTotal?: number;
  receivedEuroTotal?: number;
  giftEuroTotal?: number;
  giftSharesTotal?: number;

  // Payment counters
  paidPayments?: firebase.firestore.FieldValue;
  openPayments?: firebase.firestore.FieldValue;
  deletedPayments?: firebase.firestore.FieldValue;
  subscriptions?: InvestmentSubscriptions;
}

/**
 * Defining all the types a Payment can have.
 */
 export const enum PaymentType {
  StartSubscription = 'start subscription',
  Subscription = 'subscription',
  OneOff = 'one-off',
  GiftPurchase = 'gift-purchase',
  GiftRedeem = 'gift-redeem',
  ModifiedSubscription = 'modified-subscription',
}

/**
 * Defining all the statuses a Payment can have.
 */
export enum PaymentStatus {
  Open = 'open',
  Cancel = 'cancel',
  Pending = 'pending',
  Authorized = 'authorized',
  Expired = 'expired',
  Failed = 'failed',
  Paid = 'paid',
}

/**
 * Different payment gateways/providers we use.
 */
export enum PaymentProvider {
  PayNL = 'PayNL',
  /**
   * Manual, from Bloqadmin.
   */
  Custom = 'Custom',
}

export interface PaymentData {
  id: string,
  amount: {
    currency: string,
    value: string,
  },
  status: PaymentStatus,
  metadata: {
    uid?: string,
    euroAmount: number,
    sharesAmount: number,
    investmentId?: string,
    assetId?: string,
    paymentId?: string,
    selectedDividendsFormatYear?: [string, number];
  },
  [key: string]: any,
}

export interface Gift {
  id?: string;
  amount: number;
  asset: firebase.firestore.DocumentReference | Asset;
  claimInvestment?: firebase.firestore.DocumentReference | Investment;
  claimInvestor?: firebase.firestore.DocumentReference | Investor;
  claimPayment?: firebase.firestore.DocumentReference | Payment;
  code: string;
  createdDateTime: firebase.firestore.Timestamp;
  currency: string;
  deleted?: boolean;
  expirationDate: firebase.firestore.Timestamp;
  orderInvestment: firebase.firestore.DocumentReference | Investment;
  orderInvestor: firebase.firestore.DocumentReference | Investor;
  orderPayment: firebase.firestore.DocumentReference | Payment;
  paymentDate: firebase.firestore.Timestamp;
  treeAmount: number;
  updatedDateTime: firebase.firestore.Timestamp;
}

/**
 * Actual payment info is placed here.
 */
export interface Payment {
  id?: string;
  investor: firebase.firestore.DocumentReference | Investor;
  investment: firebase.firestore.DocumentReference | Investment;
  asset: firebase.firestore.DocumentReference | Asset;
  dividendsFormat: [string, number];
  provider: PaymentProvider;
  providerData: PaymentData;
  deleted: boolean;
  ended?: firebase.firestore.Timestamp;
  mandateId?: string;
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime?: firebase.firestore.Timestamp;
  paymentDateTime?: firebase.firestore.Timestamp;
  trees?: firebase.firestore.DocumentReference[] | InvestmentTree[];
  type: PaymentType;
  orderId?: string;
  gift?: firebase.firestore.DocumentReference | Gift;
  referral?: firebase.firestore.DocumentReference | Referral,
  coupon?: firebase.firestore.DocumentReference | Coupon,
}

export interface InvestmentEarning {
  id?: string;
  investor: firebase.firestore.DocumentReference | Investor;
  investment: firebase.firestore.DocumentReference | Investment;
  asset: firebase.firestore.DocumentReference | Asset;
  amount: number;
  createdDateTime: firebase.firestore.Timestamp;
  paymentDateTime: firebase.firestore.Timestamp;
}

export interface InvestmentTree {
  id?: string;
  investor: firebase.firestore.DocumentReference | Investor;
  investment: firebase.firestore.DocumentReference | Investment;
  asset: firebase.firestore.DocumentReference | Asset;
  payment: firebase.firestore.DocumentReference | Payment;
  createdDateTime: firebase.firestore.Timestamp;
  endDateTime?: firebase.firestore.Timestamp;
}

export interface Referral {
  id?: string,
  investor: firebase.firestore.DocumentReference | Investor,
  code: string, // we have to be sure this is unique
  currency: string,
  amount: number,
  createdDateTime: firebase.firestore.Timestamp,
  updatedDateTime: firebase.firestore.Timestamp,
  totalRedeemed: number | firebase.firestore.FieldValue,
  // Thinking on future implementations
  expirationDate?: firebase.firestore.Timestamp,
  limitPerPayment?: number,
}

export interface Redeem {
  asset: firebase.firestore.DocumentReference | Asset,
  investment: firebase.firestore.DocumentReference | Investment,
  payment: firebase.firestore.DocumentReference | Payment,
  investor: firebase.firestore.DocumentReference | Investor,
  createdDateTime: firebase.firestore.Timestamp, // Should be the same as paymentDate
  updatedDateTime: firebase.firestore.Timestamp,
  paymentDate: firebase.firestore.Timestamp,
}
