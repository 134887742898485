/**
 * Exporting all the variables depending if we are in develop or in a brand specific branch
 */

module.exports = {
  logo: 'corekees.svg',
  logoalt: 'corekees-alt.svg',
  logoShare: 'corekees.svg',
  scssVariables: './src/scss/variables/brands/_corekees.scss',
  name: 'Corekees',
  phone: '+31 10 30 71 677',
  email: 'info@corekees.com',
  website: 'https://corekees.com',
  glossary: true,
  modules: {
    landing: false,
    identification: true, // setting this to false requires manual changes
  },
  intercom: false,
  requireContractAgreement: true,
  requireQuestionnaire: false,
  requireLegalBanner: true,
};
