import { RouteConfig } from 'vue-router';
import { IdentCheckoutRouteNames, IdentifcationPath } from '@/helpers/checkout';
import { IdentRouteNames } from '@/modules/identification/identRouteNames';
import router from '@/router';

export default <RouteConfig[]>[
  {
    path: `/${IdentifcationPath}`,
    name: IdentRouteNames.MAIN,
    component: (): Promise<object> => import(/* webpackChunkName: "identification" */ '@/modules/identification/components/Identification.vue'),
    meta: {
      requiresAuth: true,
    },
    // display identification in checkout flow if it was called from there
    beforeEnter: (to, from, next): void => {
      if (from.path.includes('checkout')) {
        switch (to.name as IdentRouteNames) {
          // case IdentRouteNames.IDIN:
          //   next({
          //     name: IdentCheckoutRouteNames.IDIN,
          //     params: { id: router.currentRoute.params.id || router.currentRoute.params.investmentId },
          //   });
          //   break;
          case IdentRouteNames.WORLD:
            next({
              name: IdentCheckoutRouteNames.WORLD,
              params: { id: router.currentRoute.params.id || router.currentRoute.params.investmentId },
            });
            break;
          case IdentRouteNames.BUSINESS:
            next({
              name: IdentCheckoutRouteNames.BUSINESS,
              params: { id: router.currentRoute.params.id || router.currentRoute.params.investmentId },
            });
            break;
          default:
            next({
              name: IdentCheckoutRouteNames.MAIN,
              params: { id: router.currentRoute.params.id || router.currentRoute.params.investmentId },
            });
            break;
        }
        return;
      }
      next();
    },
    children: [
      // {
      //   path: 'idin',
      //   name: IdentRouteNames.IDIN,
      //   component: (): Promise<object> =>
      //     import(/* webpackChunkName: "identification" */ '@/modules/identification/components/idin/IdentificationIdin.vue'),
      // },
      {
        path: 'world',
        name: IdentRouteNames.WORLD,
        component: (): Promise<object> =>
          import(/* webpackChunkName: "identification" */ '@/modules/identification/components/world/IdentificationWorld.vue'),
      },
      {
        path: 'business',
        name: IdentRouteNames.BUSINESS,
        component: (): Promise<object> =>
          import(/* webpackChunkName: "identification" */ '@/modules/identification/components/business/IdentificationBusiness.vue'),
      },
    ],
  },
];
